import { Injectable } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {first, map} from 'rxjs/operators';
import {ProfileService} from './profile.service';
import * as dayjs from 'dayjs';
import {PostService} from './post.service';

export interface Lead {
  id?: string;
  permalink: string;
  _metaData: {
    createdAt: any;
    createdBy: any;
    owner: any;
    status: string;
  };
  payload?: any;
}


@Injectable({
  providedIn: 'root'
})
export class LeadService {

  constructor(private afs: AngularFirestore, private profileService: ProfileService, private postService: PostService) {
  }

  find(id: string) {
    return this.afs.collection('leads').doc(id).valueChanges().pipe(map(doc => Object.assign({}, doc, {id})));
  }

  add(permalink: string, payload = {}) {
    // todo clean link
    return this.afs.collection('leads').add({
      _metaData: {
        createdAt: new Date(),
        createdBy: this.profileService.profile,
        status: 'new'
      },
      permalink,
      payload
    });
  }

  async takeNextLead() {
    // todo figure out how to avoid the possibility of people taking the same lead
    const res: Lead[] = await this.afs.collection<Lead>('leads', q =>
      q.where('_metaData.status', '==', 'new').limit(1))
      .valueChanges({idField: 'id'})
      .pipe(first())
      .toPromise();
    const lead = res[0];
    if (lead) {
      await this.approve(lead);
    }
    return lead;
  }

  hasNewLeads() {
    return this.afs.collection<Lead>('leads', q =>
      q.where('_metaData.status', '==', 'new').limit(1))
      .valueChanges()
      .pipe(map(res => res.length > 0));
  }

  async approve(lead: Lead) {
    try {
      const res = await this.afs.collection('leads').doc(lead.id).update({
        ['_metaData.status']: 'approved',
        ['_metaData.owner']: this.profileService.profile
      });
      const post = await this.postService.create(lead.id, lead.permalink, lead.payload);
    } catch (e) {
      console.error(e);
    }
    return;
  }

  deny(id: string) {
    return this.afs.collection('leads').doc(id).update({
      ['_metaData.status']: 'denied',
      ['_metaData.owner']: this.profileService.profile
    });
  }

  reset(id: string) {
    return this.afs.collection('leads').doc(id).update({
      ['_metaData.status']: 'new',
      ['_metaData.owner']: this.profileService.profile
    });
  }

  list(status: string) {
    return this.afs
      .collection('leads', q => q.where('_metaData.status', '==', status).orderBy('_metaData.createdAt').limit(20))
      .valueChanges({idField: 'id'});
  }

  exists(link: string) {
    return this.afs
      .collection('leads', q => q.where('permalink', '==', link))
      .valueChanges()
      .pipe(first())
      .pipe(map(res => res.length > 0));
  }
}
